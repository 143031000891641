<template>
  <div class="relative">
    <div
      class="z-0 group flex items-center font-normal mt-1 w-full shadow-sm justify-between rounded-md bg-white border border-gray-200 px-3 text-sm truncate leading-5 focus:outline-none transition ease-in-out duration-300"
      :class="dynamiClasses"
    >
      <label
        class="z-10 transform bg-white absolute transition duration-500 ease-in-out text-gray-500"
        :class="isSelected ? '-translate-y-5' : 'cursor-pointer'"
        >{{ title }}
      </label>
      <div>
        <slot></slot>
      </div>

      <svg
        v-if="!loading"
        class="-mr-1 ml-2 h-5 w-5 transform duration-300 ease-in-out absolute right-2 text-gray-400 group-hover:text-blue-500"
        :class="open ? ' rotate-180' : 'rotate-0'"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <CircleLoader :color="LoaderColor.blue" v-else class="right-2" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, unref as v } from "vue";

import CircleLoader from "@/components/common/utils/loaders/CircleLoader.vue";
import { LoaderColor } from "@/common/types";

export default defineComponent({
  components: { CircleLoader },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const disabledClasses = computed(() =>
      props.disabled ? ["cursor-not-allowed"] : [""]
    );

    const loadingClasses = computed(() =>
      props.loading ? ["cursor-wait"] : [""]
    );
    const loadingOrDisabledClasses = computed(() => {
      const classes: string[] =
        props.loading || props.disabled
          ? ["bg-gray-100", "text-gray-400"]
          : [
              "bg-white",
              "focus:ring-1",
              "focus:ring-blue-500",
              "focus:ring-blue",
              "cursor-pointer",
            ];
      if (props.isSelected) {
        classes.push(...["py-2"]);
      } else {
        classes.push(...["py-5"]);
      }
      return classes;
    });

    const dynamiClasses = computed(() => [
      ...v(disabledClasses),
      ...v(loadingClasses),
      ...v(loadingOrDisabledClasses),
    ]);
    return { LoaderColor, dynamiClasses };
  },
});
</script>

<style scoped></style>
