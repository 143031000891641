
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import xml2js from "xml2js";
import { useMunicipalityStore } from "@/store/MunicipalityStore";

import { CSV_TYPE, CsvImport } from "@/common/types";
import FileDnDUploader, {
  FileDnDUploadError,
} from "@/components/common/FileDnDUploader.vue";
import MDatePicker from "@/components/common/utils/mdatepicker/MDatePicker.vue";
import { ToastType, useAppStore } from "@/store/AppStore";

const CSVTypeStr: Record<CSV_TYPE, string> = {
  [CSV_TYPE.INVESTEERINGU]: "Investeeringud",
  [CSV_TYPE.EELARVEANDMIK]: "Eelarveandmik",
};

export default defineComponent({
  components: { MDatePicker, FileDnDUploader },
  emits: {
    uploaded: () => true,
  },
  setup(_, { emit }) {
    const appStore = useAppStore();
    const municipalityStore = useMunicipalityStore();
    const today = new Date();
    const selectedDate = ref<string | null>(null);
    const dataHeaders = ref<string[] | null>(null);
    const dataRows = ref<Record<string, string>[] | null>(null);
    const dataPoints = ref<[string, string, string] | null>(null);
    const csvType = ref<CSV_TYPE | null>(null);

    const csvData = computed<CsvImport | null>(() => {
      if (
        !dataHeaders.value?.length ||
        !dataRows.value?.length ||
        dataPoints.value?.length !== 3
      )
        return null;
      return {
        headers: dataHeaders.value,
        rows: dataRows.value,
        dataPoints: dataPoints.value,
        uploadTimestamp: Date.now(),
        selectedDate: selectedDate.value ?? getInitialDate.value,
      };
    });

    const lastUpdatedFile = ref<File | null>(null);
    const getInitialDate = computed(() => today.toISOString().split("T")[0]);
    const setSelectedDate = (date: Date) =>
      (selectedDate.value = date.toISOString().split("T")[0]);
    const setInitialDate = () => (selectedDate.value = getInitialDate.value);
    onBeforeMount(() => setInitialDate());

    const csvTypeStr = computed(() =>
      csvType.value ? CSVTypeStr[csvType.value] : undefined
    );

    const isInvalidFile = ref(false);

    const getRows = (params: {
      rows?: Record<string, string>[];
      headers: string[];
    }): Record<string, string>[] | undefined => {
      const { rows, headers } = params;
      if (!rows) return undefined;
      const res = rows.map((row) => {
        for (const key in row) {
          row[key] = row[key][0].trim();
        }
        return {
          ...row,
        };
      });
      if (!headers.includes("kulu_liik")) return res;

      return res
        .filter((row) => {
          if (row.kulu_liik === "" && row.teg_ala.length === 2) return true;
          else if (
            ["15", "4502"].includes(row.kulu_liik) &&
            row.teg_ala.length !== 2
          )
            return true;
          return false;
        })
        .map((row) => ({
          ...row,
          nimi: rows.find((r) => r.teg_ala === row.teg_ala)?.nimi || "",
          teg_ala: row.teg_ala.slice(0, 2),
        }));
    };

    const onFile = (file: File) => {
      isInvalidFile.value = false;
      lastUpdatedFile.value = null;
      lastUpdatedFile.value = file;
      dataHeaders.value = null;
      dataRows.value = null;
      dataPoints.value = null;
      csvType.value = null;

      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = () => {
        const parser = new xml2js.Parser();
        if (!reader.result) return;
        parser.parseString(reader.result, (err: Error, result: any) => {
          if (err) {
            console.log(err);
            return;
          }
          const mainObjectName = Object.keys(result)[0];
          const rowsObjectName = `${mainObjectName}_row`;
          const mainObject = result[mainObjectName];
          if (!mainObject) {
            isInvalidFile.value = true;
            return;
          }

          const headers = Object.keys(mainObject[rowsObjectName][0]);
          csvType.value = headers.includes("teg_ala")
            ? CSV_TYPE.INVESTEERINGU
            : CSV_TYPE.EELARVEANDMIK;

          const rows: Record<string, string>[] | undefined = getRows({
            rows: mainObject[rowsObjectName],
            headers,
          });
          if (!rows) {
            isInvalidFile.value = true;
            return;
          }

          dataPoints.value =
            csvType.value === CSV_TYPE.INVESTEERINGU
              ? [headers[3], headers[4], headers[4]]
              : [headers[4], headers[5], headers[5]];

          dataHeaders.value = headers;
          dataRows.value = rows;
        });
      };
    };

    const upload = async () => {
      // TODO: validation
      if (!csvType.value || !csvData.value) return;
      await municipalityStore.uploadData({
        csvType: csvType.value,
        data: csvData.value,
      });

      emit("uploaded");
    };

    const onFileError = (e: FileDnDUploadError) => {
      switch (e) {
        case FileDnDUploadError.WRONG_FORMAT:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Vigane faili formaat",
            message: "Palun veenduge et fail oleks XML formaat",
          });
          break;
        case FileDnDUploadError.MULTIPLE:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Liiga palju faile",
            message: "Pulun laadige ülesse üks fail korraga",
          });
          break;
        default:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Miskit läks valesti",
            message: "Palun proovige uuesti",
          });
          break;
      }
    };

    return {
      csvData,
      isInvalidFile,
      selectedDate,
      csvTypeStr,
      lastUpdatedFile,
      upload,
      onFile,
      today,
      setSelectedDate,
      onFileError,
    };
  },
});
