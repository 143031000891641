<template>
  <div class="mt-4">
    <MunicipalityPathGuard>
      <div class="w-full">
        <UploadCsvUploader v-if="!uploaded" @uploaded="uploaded = true" />
        <UploadCsvUploaded v-else @uploadAgain="uploaded = false" />
      </div>
    </MunicipalityPathGuard>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import UploadCsvUploader from "@/components/uploadcsv/UploadCsvUploader.vue";
import UploadCsvUploaded from "@/components/uploadcsv/UploadCsvUploaded.vue";
import MunicipalityPathGuard from "@/components/common/MunicipalityPathGuard.vue";

export default defineComponent({
  components: { MunicipalityPathGuard, UploadCsvUploaded, UploadCsvUploader },
  setup() {
    const uploaded = ref(false);
    return { uploaded };
  },
});
</script>
