
import { defineComponent, ref } from "vue";
import UploadCsvUploader from "@/components/uploadcsv/UploadCsvUploader.vue";
import UploadCsvUploaded from "@/components/uploadcsv/UploadCsvUploaded.vue";
import MunicipalityPathGuard from "@/components/common/MunicipalityPathGuard.vue";

export default defineComponent({
  components: { MunicipalityPathGuard, UploadCsvUploaded, UploadCsvUploader },
  setup() {
    const uploaded = ref(false);
    return { uploaded };
  },
});
