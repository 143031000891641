<template>
  <div>
    <div class="max-w-sm flex flex-wrap mx-auto">
      <div
        v-for="weekDay of weekDays"
        :key="weekDay"
        class="w-12 mb-1 mr-1 text-center transition-all duration-50"
        :class="
          hoverWeekDay === weekDay
            ? 'text-gray-900 font-semibold'
            : 'text-gray-500'
        "
      >
        {{ weekDay }}
      </div>
      <MDatePickerDay
        v-for="day in addedEmptyDays"
        :key="`date-picekr-empty-day_${day}`"
        :showActiveDay="false"
        :daysInMonth="addedEmptyDays"
        :day="day"
        :isPlaceHolderDay="true"
      />
      <MDatePickerDay
        v-for="day of daysInMonth"
        :key="`day_picker_date_${day}`"
        :day="day"
        :daysInMonth="daysInMonth"
        :activeDay="activeDay"
        :showActiveDay="showActiveDay"
        :isPlaceHolderDay="false"
        @onDaySelect="onDaySelect"
        @mouseenter="mouseOnDay(day)"
        @mouseleave="mouseOnDay(undefined)"
      />
      <MDatePickerDay
        v-for="day in extraDays"
        :key="`date-picekr-extra-day_${day}`"
        :showActiveDay="false"
        :daysInMonth="extraDays"
        :day="day"
        :isPlaceHolderDay="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import MDatePickerDay from "@/components/common/utils/mdatepicker/MDatePickerDay.vue";

enum WeekDay {
  E = "E",
  T = "T",
  K = "K",
  N = "N",
  R = "R",
  L = "L",
  P = "P",
}

export default defineComponent({
  name: "MDatePickerDays",
  components: { MDatePickerDay },
  emits: {
    onDaySelect: (day: unknown) => typeof day === "number",
  },
  props: {
    daysInMonth: {
      type: Number,
      required: true,
    },
    activeDay: {
      type: Number,
      required: false,
    },
    showActiveDay: {
      type: Boolean,
      required: true,
    },
    firstCalendarDayOfActiveMonth: {
      // 1. monday, 2. tuesday ...
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const weekDays: WeekDay[] = Object.values(WeekDay);
    const hoverWeekDay = ref<WeekDay | undefined>(undefined);
    const onDaySelect = (day: number) => emit("onDaySelect", day);
    const addedEmptyDays = computed(
      () => props.firstCalendarDayOfActiveMonth - 1
    );
    const extraDays = computed(() =>
      Math.abs(((props.daysInMonth + addedEmptyDays.value) % 7) - 7)
    );
    const mouseOnDay = (day?: number | undefined) => {
      hoverWeekDay.value = day
        ? weekDays[(6 + day + addedEmptyDays.value) % 7]
        : undefined;
    };
    return {
      onDaySelect,
      addedEmptyDays,
      weekDays,
      extraDays,
      mouseOnDay,
      hoverWeekDay,
    };
  },
});
</script>

<style scoped></style>
