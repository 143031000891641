<template>
  <div>
    <div
      :class="dynamicClasses"
      class="inline-flex w-full justify-between group text-gray-900 px-4 py-2 text-sm font-normal hover:font-medium leading-5 focus:outline-none transition-all duration-100"
    >
      <slot></slot>
      <svg
        v-show="active"
        class="h-5 w-5"
        :class="
          loading ? 'text-gray-600' : 'text-blue-600 group-hover:text-white'
        "
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, unref as v } from "vue";

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const activeClasses = computed(() =>
      props.active ? ["font-medium"] : ["font-normal"]
    );
    const disabledClasses = computed(() =>
      props.loading
        ? ["cursor-wait"]
        : [" hover:text-white hover:bg-blue-600 cursor-pointer"]
    );

    const dynamicClasses = computed(() => [
      ...v(disabledClasses),
      ...v(activeClasses),
    ]);
    return { dynamicClasses };
  },
});
</script>

<style scoped></style>
