
import { computed, defineComponent, unref as v } from "vue";

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const activeClasses = computed(() =>
      props.active ? ["font-medium"] : ["font-normal"]
    );
    const disabledClasses = computed(() =>
      props.loading
        ? ["cursor-wait"]
        : [" hover:text-white hover:bg-blue-600 cursor-pointer"]
    );

    const dynamicClasses = computed(() => [
      ...v(disabledClasses),
      ...v(activeClasses),
    ]);
    return { dynamicClasses };
  },
});
