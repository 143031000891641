<template>
  <div class="relative">
    <div>
      <slot name="trigger"></slot>
    </div>
    <MDropDownMenuTransition>
      <div v-show="open" class="w-full absolute mt-2 shadow-lg">
        <div
          class="divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-gray-100 overflow-x-hidden absolute z-50 w-full"
          :class="fixedHeight ? 'max-h-44' : ''"
        >
          <slot name="options">
            <MSimpleDropdownOption :active="false">
              <div class="inline-flex">
                <div class="font-normal truncate">Valikud puuduvad...</div>
              </div>
            </MSimpleDropdownOption>
          </slot>
        </div>
      </div>
    </MDropDownMenuTransition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MSimpleDropdownOption from "@/components/common/utils/dropdowns/MSimpleDropdownOption.vue";
import MDropDownMenuTransition from "@/components/common/utils/dropdowns/MDropDownMenuTransition.vue";

export default defineComponent({
  components: { MDropDownMenuTransition, MSimpleDropdownOption },
  props: {
    open: {
      type: Boolean,
      required: true,
    },

    fixedHeight: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style scoped></style>
