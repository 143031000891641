<template>
  <div
    class="w-5 h-5 border-2 rounded-full loader absolute"
    :class="colorClass"
  ></div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { LoaderColor } from "@/common/types";

export default defineComponent({
  props: {
    color: {
      type: String as () => LoaderColor,
      required: false,
      default: LoaderColor.WHITE,
    },
  },
  setup(props) {
    const colorClass = computed(() => {
      switch (props.color) {
        case LoaderColor.WHITE:
          return "border-white";
        case LoaderColor.blue:
          return "border-blue-600";
        case LoaderColor.WARNING:
          return "border-yellow-300";
        default:
          return "border-white";
      }
    });

    return { colorClass };
  },
});
</script>

<style>
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>
