<template>
  <div>
    <label class="block text-sm font-normal mb-1 text-gray-700">
      {{ label }}</label
    >
    <MSimpleDropdown :open="isDropdown" :id="dropDownID" :fixedHeight="false">
      <template #trigger>
        <MSimpleDropdownButton
          v-model:open="isDropdown"
          @click="setIsDropdown(!isDropdown)"
          :disabled="false"
          :loading="false"
          :isSelected="!!selectedDate"
          :title="title"
        >
          {{ buttonText }}
        </MSimpleDropdownButton>
      </template>
      <template #options>
        <div class="px-2 sm:px-6 md:px-2 py-3 mx-auto">
          <div class="w-full items-center flex justify-between my-2">
            <div class="inline-flex flex space-x-2">
              <div class="group cursor-pointer" @click="changeYear(-1)">
                <div
                  class="group-hover:bg-blue-500 p-2 border border-gray-300 group-hover:border-blue-500 rounded-xl flex items-center transition duration-300 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-gray-500 group-hover:text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div class="group cursor-pointer" @click="changeMonth(-1)">
                <div
                  class="group-hover:bg-blue-500 p-2 border border-gray-300 group-hover:border-blue-500 rounded-xl flex items-center transition duration-300 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-gray-500 group-hover:text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="text-gray-700 text-xl font-light">
              {{ activeMonthStr }} - {{ activeYear }}
            </div>
            <div class="inline-flex flex space-x-2">
              <div class="group cursor-pointer" @click="changeMonth(1)">
                <div
                  class="group-hover:bg-blue-500 p-2 border border-gray-300 group-hover:border-blue-500 rounded-xl flex items-center transition duration-300 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-gray-500 group-hover:text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div class="group cursor-pointer" @click="changeYear(1)">
                <div
                  class="group-hover:bg-blue-500 p-2 border border-gray-300 group-hover:border-blue-500 rounded-xl flex items-center transition duration-300 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-gray-500 group-hover:text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <MDatePickerDays
            :daysInMonth="daysInMonth"
            :activeDay="activeDay"
            :showActiveDay="showActiveDay"
            :firstCalendarDayOfActiveMonth="firstCalendarDayOfActiveMonth"
            @onDaySelect="changeDay"
          />
        </div>
      </template>
    </MSimpleDropdown>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  unref as v,
  watch,
} from "vue";
import MSimpleDropdown from "@/components/common/utils/dropdowns/MSimpleDropdown.vue";
import MSimpleDropdownButton from "@/components/common/utils/dropdowns/MSimpleDropdownButton.vue";
import MDatePickerDays from "@/components/common/utils/mdatepicker/MDatePickerDays.vue";

export default defineComponent({
  name: "MDatePicker",
  components: { MDatePickerDays, MSimpleDropdownButton, MSimpleDropdown },
  emits: {
    onDate: (date: unknown) =>
      Object.prototype.toString.call(date) === "[object Date]" || true,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    resetSelectedToggle: {
      type: Boolean,
      required: false,
    },
    initialDate: {
      type: Date,
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const months = [
      "Jaanuar",
      "Veebruar",
      "Märts",
      "Aprill",
      "Mai",
      "Juuni",
      "Juuli",
      "August",
      "September",
      "Oktoober",
      "November",
      "Detsember",
    ];
    const today = new Date();
    const selectedDate = ref<Date | undefined>(undefined);
    const activeYear = ref<number>(today.getFullYear());
    const activeMonth = ref<number>(today.getMonth());
    const activeDay = ref<number>(today.getDate());
    const activeMonthStr = computed(() => months[v(activeMonth)]);
    const showActiveDay = computed(() => {
      if (selectedDate.value)
        return (
          activeYear.value === selectedDate.value.getFullYear() &&
          activeMonth.value === selectedDate.value.getMonth()
        );
      else return false;
    });

    const changeYear = (direction: number) => (activeYear.value += direction);

    const changeMonth = (direction: number) => {
      if (direction === 1) {
        if (v(activeMonth) === 11) {
          activeMonth.value = 0;
          activeYear.value += 1;
        } else {
          activeMonth.value += 1;
        }
      } else if (direction === -1) {
        if (v(activeMonth) === 0) {
          activeMonth.value = 11;
          activeYear.value -= 1;
        } else {
          activeMonth.value -= 1;
        }
      }
    };
    const isDropdown = ref(false);
    const dropDownID = "datepicker-dd";
    const changeDay = (day: number) => {
      activeDay.value = day;
      selectedDate.value = new Date(
        activeYear.value,
        activeMonth.value,
        activeDay.value,
        23,
        59,
        59
      );
      isDropdown.value = false;
      emit("onDate", selectedDate.value);
    };

    const daysInMonth = computed(() =>
      new Date(activeYear.value, activeMonth.value + 1, 0).getDate()
    );
    const firstCalendarDayOfActiveMonth = computed(() => {
      const day = new Date(activeYear.value, activeMonth.value, 1).getDay();
      return day === 0 ? 7 : day;
    });

    const buttonText = computed(() => {
      if (selectedDate.value) {
        const day = selectedDate.value.getDate();
        const month = selectedDate.value.getMonth();
        const year = selectedDate.value.getFullYear();
        return `${day} ${months[month]} ${year}`;
      } else {
        return "";
      }
    });

    const title = computed(() => props.placeholder);
    const setIsDropdown = (newValue: boolean) => (isDropdown.value = newValue);
    onMounted(async () => {
      if (props.initialDate) {
        selectedDate.value = props.initialDate;
        activeYear.value = selectedDate.value.getFullYear();
        activeMonth.value = selectedDate.value.getMonth();
        activeDay.value = selectedDate.value.getDate();
        emit("onDate", selectedDate.value);
      }
      window.addEventListener("click", (e: MouseEvent) => {
        if (v(isDropdown)) {
          const target = e.target as HTMLDivElement;
          if (!target.closest(`#${dropDownID}`)) setIsDropdown(false);
        }
      });
    });

    watch(
      () => props.resetSelectedToggle,
      () => {
        selectedDate.value = undefined;
        activeYear.value = today.getFullYear();
        activeMonth.value = today.getMonth();
        activeDay.value = today.getDate();
        emit("onDate", selectedDate.value);
      }
    );
    return {
      daysInMonth,
      activeMonthStr,
      changeMonth,
      changeYear,
      activeYear,
      activeDay,
      changeDay,
      showActiveDay,
      isDropdown,
      dropDownID,
      title,
      setIsDropdown,
      buttonText,
      selectedDate,
      firstCalendarDayOfActiveMonth,
    };
  },
});
</script>

<style scoped></style>
