
import { defineComponent } from "vue";
import MSimpleDropdownOption from "@/components/common/utils/dropdowns/MSimpleDropdownOption.vue";
import MDropDownMenuTransition from "@/components/common/utils/dropdowns/MDropDownMenuTransition.vue";

export default defineComponent({
  components: { MDropDownMenuTransition, MSimpleDropdownOption },
  props: {
    open: {
      type: Boolean,
      required: true,
    },

    fixedHeight: {
      type: Boolean,
      default: true,
    },
  },
});
