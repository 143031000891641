<template>
  <div class="px-6 max-w-screen-2xl mx-auto">
    <FileDnDUploader
      title="Andmete üleslaadimine"
      accept="text/xml"
      @onFile="onFile"
      @onError="onFileError"
    />

    <div class="mt-10 w-full" v-if="csvData">
      <h2
        class="text-center text-3xl leading-8 font-semibold tracking-tight text-primary-teal sm:text-4xl"
      >
        {{ csvTypeStr }}
      </h2>
      <div
        class="mt-6 lg:mt-0 w-full flex flex-wrap items-end py-3 justify-between"
      >
        <MDatePicker
          class="w-full md:w-1/2 xl:w-1/3"
          label=""
          placeholder="Andmed seisuga"
          :initialDate="today"
          @onDate="setSelectedDate"
        />
        <button
          @click="upload"
          class="w-full md:w-min whitespace-nowrap mt-4 md:mt-0 cursor-pointer inline-flex justify-center items-center py-2.5 px-5 border shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-transparent hover:text-indigo-600 border-indigo-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 transition duration-300 ease-in-out"
        >
          Lae {{ csvTypeStr }} andmebaasi
        </button>
      </div>
      <div class="mt-2 md:mt-0 pb-6">
        <div class="border border-gray-200 rounded-md">
          <table class="min-w-full">
            <thead class="bg-gray-100 border-b border-gray-200">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-sm font-medium text-black uppercase tracking-wide"
                  v-for="(cell, index) in csvData.headers"
                  :key="index"
                >
                  {{ cell }}
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100">
              <tr
                class="hover:bg-gray-200 transition duration-300 ease-in-out"
                v-for="(row, rowIndex) in csvData.rows"
                :key="rowIndex"
                :class="rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
              >
                <td
                  class="px-6 py-2 font-normal"
                  v-for="(header, cellIndex) in csvData.headers"
                  :key="cellIndex"
                  :class="
                    [0, 1, 2].includes(cellIndex)
                      ? 'text-gray-500'
                      : 'text-black'
                  "
                >
                  {{ row[header] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="w-full mt-10" v-else-if="isInvalidFile">
      <h2
        class="text-center text-3xl leading-8 font-extrabold text-black sm:text-4xl"
      >
        Fail
        <span class="text-red-500">"{{ lastUpdatedFile?.name }}"</span> tundub
        olevat vigane!
      </h2>
      <p
        class="mt-4 max-w-3xl mx-auto text-center text-base sm:text-lg font-light text-gray-500"
      >
        Palun kontrollige üle fail mida proovisite ülesse laadida ja veenduge
        selle korrektsuses.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import xml2js from "xml2js";
import { useMunicipalityStore } from "@/store/MunicipalityStore";

import { CSV_TYPE, CsvImport } from "@/common/types";
import FileDnDUploader, {
  FileDnDUploadError,
} from "@/components/common/FileDnDUploader.vue";
import MDatePicker from "@/components/common/utils/mdatepicker/MDatePicker.vue";
import { ToastType, useAppStore } from "@/store/AppStore";

const CSVTypeStr: Record<CSV_TYPE, string> = {
  [CSV_TYPE.INVESTEERINGU]: "Investeeringud",
  [CSV_TYPE.EELARVEANDMIK]: "Eelarveandmik",
};

export default defineComponent({
  components: { MDatePicker, FileDnDUploader },
  emits: {
    uploaded: () => true,
  },
  setup(_, { emit }) {
    const appStore = useAppStore();
    const municipalityStore = useMunicipalityStore();
    const today = new Date();
    const selectedDate = ref<string | null>(null);
    const dataHeaders = ref<string[] | null>(null);
    const dataRows = ref<Record<string, string>[] | null>(null);
    const dataPoints = ref<[string, string, string] | null>(null);
    const csvType = ref<CSV_TYPE | null>(null);

    const csvData = computed<CsvImport | null>(() => {
      if (
        !dataHeaders.value?.length ||
        !dataRows.value?.length ||
        dataPoints.value?.length !== 3
      )
        return null;
      return {
        headers: dataHeaders.value,
        rows: dataRows.value,
        dataPoints: dataPoints.value,
        uploadTimestamp: Date.now(),
        selectedDate: selectedDate.value ?? getInitialDate.value,
      };
    });

    const lastUpdatedFile = ref<File | null>(null);
    const getInitialDate = computed(() => today.toISOString().split("T")[0]);
    const setSelectedDate = (date: Date) =>
      (selectedDate.value = date.toISOString().split("T")[0]);
    const setInitialDate = () => (selectedDate.value = getInitialDate.value);
    onBeforeMount(() => setInitialDate());

    const csvTypeStr = computed(() =>
      csvType.value ? CSVTypeStr[csvType.value] : undefined
    );

    const isInvalidFile = ref(false);

    const getRows = (params: {
      rows?: Record<string, string>[];
      headers: string[];
    }): Record<string, string>[] | undefined => {
      const { rows, headers } = params;
      if (!rows) return undefined;
      const res = rows.map((row) => {
        for (const key in row) {
          row[key] = row[key][0].trim();
        }
        return {
          ...row,
        };
      });
      if (!headers.includes("kulu_liik")) return res;

      return res
        .filter((row) => {
          if (row.kulu_liik === "" && row.teg_ala.length === 2) return true;
          else if (
            ["15", "4502"].includes(row.kulu_liik) &&
            row.teg_ala.length !== 2
          )
            return true;
          return false;
        })
        .map((row) => ({
          ...row,
          nimi: rows.find((r) => r.teg_ala === row.teg_ala)?.nimi || "",
          teg_ala: row.teg_ala.slice(0, 2),
        }));
    };

    const onFile = (file: File) => {
      isInvalidFile.value = false;
      lastUpdatedFile.value = null;
      lastUpdatedFile.value = file;
      dataHeaders.value = null;
      dataRows.value = null;
      dataPoints.value = null;
      csvType.value = null;

      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = () => {
        const parser = new xml2js.Parser();
        if (!reader.result) return;
        parser.parseString(reader.result, (err: Error, result: any) => {
          if (err) {
            console.log(err);
            return;
          }
          const mainObjectName = Object.keys(result)[0];
          const rowsObjectName = `${mainObjectName}_row`;
          const mainObject = result[mainObjectName];
          if (!mainObject) {
            isInvalidFile.value = true;
            return;
          }

          const headers = Object.keys(mainObject[rowsObjectName][0]);
          csvType.value = headers.includes("teg_ala")
            ? CSV_TYPE.INVESTEERINGU
            : CSV_TYPE.EELARVEANDMIK;

          const rows: Record<string, string>[] | undefined = getRows({
            rows: mainObject[rowsObjectName],
            headers,
          });
          if (!rows) {
            isInvalidFile.value = true;
            return;
          }

          dataPoints.value =
            csvType.value === CSV_TYPE.INVESTEERINGU
              ? [headers[3], headers[4], headers[4]]
              : [headers[4], headers[5], headers[5]];

          dataHeaders.value = headers;
          dataRows.value = rows;
        });
      };
    };

    const upload = async () => {
      // TODO: validation
      if (!csvType.value || !csvData.value) return;
      await municipalityStore.uploadData({
        csvType: csvType.value,
        data: csvData.value,
      });

      emit("uploaded");
    };

    const onFileError = (e: FileDnDUploadError) => {
      switch (e) {
        case FileDnDUploadError.WRONG_FORMAT:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Vigane faili formaat",
            message: "Palun veenduge et fail oleks XML formaat",
          });
          break;
        case FileDnDUploadError.MULTIPLE:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Liiga palju faile",
            message: "Pulun laadige ülesse üks fail korraga",
          });
          break;
        default:
          appStore.addToast({
            toastType: ToastType.ERROR,
            title: "Miskit läks valesti",
            message: "Palun proovige uuesti",
          });
          break;
      }
    };

    return {
      csvData,
      isInvalidFile,
      selectedDate,
      csvTypeStr,
      lastUpdatedFile,
      upload,
      onFile,
      today,
      setSelectedDate,
      onFileError,
    };
  },
});
</script>
