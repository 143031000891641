
import { computed, defineComponent } from "vue";
import { LoaderColor } from "@/common/types";

export default defineComponent({
  props: {
    color: {
      type: String as () => LoaderColor,
      required: false,
      default: LoaderColor.WHITE,
    },
  },
  setup(props) {
    const colorClass = computed(() => {
      switch (props.color) {
        case LoaderColor.WHITE:
          return "border-white";
        case LoaderColor.blue:
          return "border-blue-600";
        case LoaderColor.WARNING:
          return "border-yellow-300";
        default:
          return "border-white";
      }
    });

    return { colorClass };
  },
});
