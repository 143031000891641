<template>
  <div
    class="w-12 h-9 mr-1 text-center pt-1 mb-1 rounded-md transition-all duration-50"
    :class="isPlaceHolderDay ? 'bg-gray-50' : dynamicClasses"
    @click="onDaySelect"
  >
    <div v-show="!isPlaceHolderDay">{{ day }}</div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "MDatePickerDay",
  emits: {
    onDaySelect: (day: unknown) => typeof day === "number",
  },
  props: {
    day: {
      type: Number,
      required: true,
    },
    daysInMonth: {
      type: Number,
      required: true,
    },
    activeDay: {
      type: Number,
      required: false,
    },
    showActiveDay: {
      type: Boolean,
      required: true,
    },
    isPlaceHolderDay: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isDayActive = computed(
      () => props.activeDay === props.day && props.showActiveDay
    );
    const dynamicClasses = computed(() => {
      const classes: string[] = ["border", "border-gray-200"];
      if (isDayActive.value) {
        classes.push(...["bg-blue-600", "text-white"]);
      } else {
        classes.push(
          ...["font-light", "cursor-pointer", "hover:bg-indigo-100"]
        );
      }
      return classes;
    });

    const onDaySelect = () => {
      if (!isDayActive.value) emit("onDaySelect", props.day);
    };
    return { dynamicClasses, onDaySelect };
  },
});
</script>

<style scoped></style>
