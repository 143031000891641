
import { defineComponent } from "vue";

export default defineComponent({
  name: "UploadCsvUploaded",
  emits: {
    uploadAgain: () => true,
  },
  setup(_, { emit }) {
    const uploadAgain = () => emit("uploadAgain");
    return { uploadAgain };
  },
});
