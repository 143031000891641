<template>
  <div class="w-full mt-40 text-center">
    <h2
      class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
    >
      Fail edukalt ülesse laetud
    </h2>
    <p class="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
      Teie andmed on uuendatud, kui soovite veel andmeid ülesse laadida vajutage
      <strong
        @click="uploadAgain"
        class="whitespace-nowrap cursor-pointer hover:text-indigo-700"
        >Lae veel andmeid ülesse</strong
      >
    </p>
    <div>
      <button
        @click="uploadAgain"
        class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mt-4 cursor-pointer inline-flex justify-center items-center py-2 px-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition duration-300 ease-in-out"
      >
        Lae veel andmeid ülesse
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UploadCsvUploaded",
  emits: {
    uploadAgain: () => true,
  },
  setup(_, { emit }) {
    const uploadAgain = () => emit("uploadAgain");
    return { uploadAgain };
  },
});
</script>

<style scoped></style>
